import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import axios from 'axios'
import {callApi, useCallApi} from '../../modules/utils'
import {ItemsProducts} from '../../components/produits/ItemsProducts'
import {TablesWidget10} from '../../../_metronic/partials/widgets'
import {UsersListPagination} from '../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import {Pagination} from '@mui/material'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {ListCategorie} from '../../components/fournisseur/ListeFournisseur'

interface Row {
  id: number
  checked: boolean
}
export const Fournisseur = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  function handlePageChange(event: any, value: any) {
    setCurrentPage(value)
  }
  return (
    <>
      <ToastContainer />

      <ListCategorie
        states={{currentPage, setCurrentPage, totalItems, setTotalItems, setTotalPages}}
      />
      <Pagination
        count={totalPages}
        page={currentPage}
        color='primary'
        onChange={handlePageChange}
        className='d-flex justify-content-center'
      />
    </>
  )
}
