import React, { ChangeEvent, useEffect, useState } from 'react'
import { useCallApi } from '../../modules/utils'
import { toast } from 'react-toastify'
import { Loading } from '../Loading'

type Props = {
  data: any

  // deleteVille:(id:number)=>void
}
export const ModalUpdateClient: React.FC<Props> = ({ data }) => {
  const callApi = useCallApi()

  const [loading, setLoading] = useState(false)
  const [chejed, setchejed] = useState(data.attributes.status === '1')
  const [roles, setRoles] = useState<any>([])

  const [ClientUpdate, setClientUpdate] = useState({
    name: data.attributes.name,
    email: data.attributes.email,
    address: data.attributes.address,
    phone: data.attributes.phone,

  })

  const updateClient = async () => {
    setLoading(true)
    const dataTosend = {

      phone: ClientUpdate.phone,
      email: ClientUpdate.email,
      name: ClientUpdate.name,
      address: ClientUpdate.address,

    }

    try {
      const dat = await callApi({
        route: `api/customers/` + data.id,

        method: 'PUT',

        body: dataTosend,
      })
      toast.success('modifié avec succes')

      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)

      setLoading(false)
    }

    // setLoading(false)
    // Toast.success('modifié')
  }


  useEffect(() => {
    setClientUpdate({
      name: data.attributes.name,
      email: data.attributes.email,
      address: data.attributes.address,
      phone: data.attributes.phone,
    })

  }, [data.attributes]);

  return (
    <>
      <div
        className='modal fade'
        id={'ModalUpdateClient' + data.id}
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' style={{ maxWidth: '50%' }}>
          <div className='modal-content'>
            <div className='modal-header ' >
              <h1 className='modal-title fs-5' id='exampleModalLabel'>
                Modifer Client
              </h1>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <form>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Nom & prenom :
                      </label>
                      <input
                        value={ClientUpdate.name}
                        onChange={(e: any) =>
                          setClientUpdate({ ...ClientUpdate, name: e.target.value })
                        }
                        name='name'
                        type='text'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Email:
                      </label>
                      <input
                        value={ClientUpdate.email}
                        onChange={(e: any) =>
                          setClientUpdate({ ...ClientUpdate, email: e.target.value })
                        }
                        name='email'
                        type='email'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        addresse :
                      </label>
                      <input
                        value={ClientUpdate.address}
                        onChange={(e: any) =>
                          setClientUpdate({ ...ClientUpdate, address: e.target.value })
                        }
                        name='addresse'
                        type='text'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                      />
                    </div>
                  </div>

                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Tel :
                      </label>
                      <input
                        value={ClientUpdate.phone}
                        onChange={(e: any) =>
                          setClientUpdate({ ...ClientUpdate, phone: e.target.value })
                        }
                        name='phone'
                        type='phone'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                      />
                    </div>
                  </div>
                </div>



              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
              {loading ? (
                <button type='button' className='btn btn-primary'>
                  <Loading />
                </button>
              ) : (
                <button type='submit'onClick={updateClient} className='btn btn-primary'>
                  valider
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
