import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {ModalAddUtilisateur} from './ModalAddUtilisateur'
import {Link} from 'react-router-dom'
import {ProductDetails} from '../../../pages/products/ProductDetails'
import {ModalUpdateUtilisateur} from './ModalUpdateUtilisateur'

type Props = {
  data: any
  handleProductCheckboxChange: any
  deleteProduct?: (id: number) => void
}
export const ItemsUtilisateurs: React.FC<Props> = ({
  data,
  handleProductCheckboxChange,
  deleteProduct,
}) => {
  return (
    <>
      {' '}
      <tr>
        <td>
          {' '}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data.checked}
              onChange={handleProductCheckboxChange(data.id)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            {/* / */}

            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data.id} />
              <span className='text-dark fw-bold  d-block fs-6'>{data.attributes.first_name}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark fw-bold  d-block fs-6'>{data.attributes.last_name}</span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center text-center flex-column'>
            <span
              className='fw-bold  d-block fs-6 badge badge-light-success mx-auto'
              style={{width: 'fit-content'}}
            >
              {data.attributes?.role[0]?.display_name}
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            {}
            <span className='text-dark fw-bold text-center d-block fs-6'>
              <div className='card-toolbar ml-3 '>
                <span
                  style={{width: 'fit-content'}}
                  className={
                    data.attributes.status == 1
                      ? ' badge badge-light-success'
                      : ' badge badge-light-danger  ' + 'fw-bolder me-auto px-2 py-3'
                  }
                >
                  {data.attributes.status == 1 ? 'active' : 'desactive'}
                </span>
              </div>
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                data-bs-toggle='modal'
                data-bs-target={'#ModalUpdateUtilisateur' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
              <ModalUpdateUtilisateur data={data} />
            

             

              {/* <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      // onClick={()=>deleteProduct(data.id)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a> */}
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
